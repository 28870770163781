import { createContext, useEffect, useReducer } from "react";
import axios from "axios";

// CUSTOM COMPONENT
import { MatxLoading } from "app/components";

const initialState = {
  user: null,
  isInitialized: false,
  isAuthenticated: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialized: true, user };
    }

    case "LOGIN": {
      return { ...state, isAuthenticated: true, user: action.payload.user };
    }

    case "LOGOUT": {
      return { ...state, isAuthenticated: false, user: null };
    }

    case "REGISTER": {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => {},
  logout: () => {},
  register: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initializeAuth = async () => {
    try {
      const currentDate = new Date().toISOString();
      const email = localStorage.getItem('email');
      const token = localStorage.getItem('token');
      const expirationDate = localStorage.getItem('expirationDate');
      if (currentDate >= expirationDate) {
        logout();
    }
      const headers = {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      };

      if (email) {
        const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/login/profile`, { email }, { headers });
        const user = data[0];
        

        dispatch({ type: "INIT", payload: { isAuthenticated: true, user } });
      } else {
         dispatch({ type: "INIT", payload: { isAuthenticated: false, user: null } });
      }
    } catch (err) {
      console.error("Error in initializeAuth:", err);
      dispatch({ type: "INIT", payload: { isAuthenticated: false, user: null } });
    }
  };

  const login = async (email, password) => {
    try {
      const token = localStorage.getItem('token');
     
      const headers = {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      };

      const response = await axios.post(`${process.env.REACT_APP_URL_API}/login`, { email, password }, { headers });
      const { user } = response.data;

      dispatch({ type: "LOGIN", payload: { user } });

      // Reinitialize auth state after login
      await initializeAuth();
    } catch (err) {
      console.error("Error in login:", err);
    }
  };

  const register = async (email, username, password) => {
    try {
      const response = await axios.post("/api/auth/register", { email, username, password });
      const { user } = response.data;

      dispatch({ type: "REGISTER", payload: { user } });
    } catch (err) {
      console.error("Error in registration:", err);
    }
  };

  const logout = () => {
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    initializeAuth();
  }, []); // Run only once when the component mounts

  // Show loader while initializing
  if (!state.isInitialized) return <MatxLoading />;

  return (
    <AuthContext.Provider value={{ ...state, method: "JWT", login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
