import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "app/components/Loadable";
import useAuth from "app/hooks/useAuth";
import axios from "axios";



// 2Holding Routers - Cadastro
const AppCompaniesCreate = Loadable(lazy(() => import("./2holding/companies")));
const AppDriversCreate = Loadable(lazy(() => import("./2holding/drivers")));
const Veiculos = Loadable(lazy(() => import("./2holding/vehicles/index")));


// 2Holding Routers - Lava Car
const AppLavaCar= Loadable(lazy(() => import("./2holding/modules/lavacar")));

// 2Holding Routers - Gerencial
const AppUserCreate = Loadable(lazy(() => import("./2holding/users")));

const userPermission = ["1", "2", "7"];

const checkPermission = (requiredPermission) => {
  return userPermission.includes(requiredPermission);
};

const materialRoutes = [

  // 2Holding Routers - Cadastro
  { path: "/companies/create", 
  element: checkPermission("2") ? <AppCompaniesCreate /> : <Navigate to="/access-denied" /> },

  { path: "/vehicles/create", 
    element: checkPermission("2") ? <Veiculos /> : <Navigate to="/access-denied" /> },

  { path: "/drivers/create", 
  element: checkPermission("2") ? <AppDriversCreate /> : <Navigate to="/access-denied" /> },

    // 2Holding Routers - Cadastro
    { path: "/modules/lavacar", 
    element: checkPermission("7") ? <AppLavaCar /> : <Navigate to="/access-denied" /> },

  // 2Holding Routers - Gerencial
  {
    path: "/users",
    element: checkPermission("1") ? <AppUserCreate /> : <Navigate to="/access-denied" />
  }
];

export default materialRoutes;
